/**
 * Sitemap
 * 	Referência: https://github.com/cheap-glitch/vue-cli-plugin-sitemap#readme
 * 	Adicionar o seguinte na rota em que precise alterar manualmente os valores do sitemap, com os respectivos valores:
		meta: {
			sitemap: {
				lastmod: new Date(),
				priority: 0.8,
				changefreq: 'daily',
			}
		}
 */

const isNotAuthenticated = (to, from, next) => {
	if(localStorage.getItem('TOKEN')) {
		return next()
	} else {
		next({ name: 'Login', query: { redirecting: '' } })
	}
}

const isAuthenticated = (to, from, next) => {
	if(localStorage.getItem('TOKEN')) {
		return next({ name: 'Painel', params: {id: localStorage.getItem('USER_ID')} })
	} else {
		return next()
	}
}

let routes = [
	{
		path: '/',
		name: 'Home',
		beforeEnter: isAuthenticated,
		component: () => import(/* webpackChunkName: "home" */ '@views/Home/Home'),
	},
	{
		path: '/painel',
		name: 'Painel',
		beforeEnter: isNotAuthenticated,
		component: () => import(/* webpackChunkName: "painel" */ '@views/Painel/Painel'),
	},
	{
		path: '/novo-usuario',
		name: 'Register',
		beforeEnter: isNotAuthenticated,
		component: () => import(/* webpackChunkName: "register" */ '@views/Register/Register'),
	},
	{
		path: '/cupons',
		name: 'Coupons',
		beforeEnter: isNotAuthenticated,
		component: () => import(/* webpackChunkName: "coupons" */ '@views/Coupons/Coupons'),
	},
	{
		path: '/recuperar-senha',
		name: 'Recovery',
		beforeEnter: isAuthenticated,
		component: () => import(/* webpackChunkName: "recovery" */ '@views/Recovery/Recovery'),
	},
	{
		path: '/relatorios',
		name: 'Reports',
		beforeEnter: isNotAuthenticated,
		component: () => import(/* webpackChunkName: "reports" */ '@views/Reports/Reports'),
	},
]

export default routes
